
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsdff88092e5c7abd4fec06126cc0d1826en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'dff88092e5c7abd4fec06126cc0d1826', flattenObject(nsdff88092e5c7abd4fec06126cc0d1826en_US), true, true);
        
            import nsdff88092e5c7abd4fec06126cc0d1826en from './en.yaml'
            i18n.addResourceBundle('en', 'dff88092e5c7abd4fec06126cc0d1826', flattenObject(nsdff88092e5c7abd4fec06126cc0d1826en), true, true);
        
        export default 'dff88092e5c7abd4fec06126cc0d1826';
    