export function getDisplayName (WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export function makeId (prefix) {
    return (
        prefix +
    Math.random()
        .toString(36)
        .substr(2, 9)
    )
}

export function wrapText (string, maxLength = 18) {
    return string.replace(new RegExp(`(?![^\\n]{1,${maxLength}}$)([^\\n]{1,${maxLength}})\\s`, 'g'), '$1\n').split('\n')
}
