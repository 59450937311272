
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsddaa00e9a260eebeb1944ff0976b3b8een_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'ddaa00e9a260eebeb1944ff0976b3b8e', flattenObject(nsddaa00e9a260eebeb1944ff0976b3b8een_US), true, true);
        
            import nsddaa00e9a260eebeb1944ff0976b3b8een from './en.yaml'
            i18n.addResourceBundle('en', 'ddaa00e9a260eebeb1944ff0976b3b8e', flattenObject(nsddaa00e9a260eebeb1944ff0976b3b8een), true, true);
        
        export default 'ddaa00e9a260eebeb1944ff0976b3b8e';
    