// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import setFavicons from 'util/setFavicons'

import { withTranslation } from 'react-i18next'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

class Favicon extends React.PureComponent {
    componentDidMount () {
        const favicon = this.props.theme.favicon
        setFavicons(favicon)
    }

    render () {
        return null
    }
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Favicon))
